import React from 'react'
import QuickPartners from '../components/homeSections/partners'
// import OtherPartners from '../components/partnerSections/OtherPartners'
// import Layout from "../components/layout"

export default function partners() {
    return (
        <div>
            <QuickPartners />
            {/* <OtherPartners /> */}
            
        </div>
    )
}
