import { Link } from "gatsby"
import React from "react"
import CiiIcon from "../../assets/images/icons/cii.svg"
import ClaIcon from "../../assets/images/icons/cla.svg"
import VidhyaIcon from "../../assets/images/icons/vid.svg"
import IlaIcon from "../../assets/images/icons/ila.svg"
import LegoDeskIcon from "../../assets/images/icons/op1.svg"
import IResolveIcon from "../../assets/images/icons/op2.svg"
import Centre4MediationIcon from "../../assets/images/icons/op3.svg"
import BombayChamberIcon from "../../assets/images/icons/op4.svg"


const data1 = [
  {
    icon: CiiIcon,
    title: "Thought Leadership Partner",
    to: "https://www.cii.in/",
  }
] 
const data2 = [
  
  {
    icon: BombayChamberIcon,
    title: " ",
    to: "http://www.bombaychamber.com/",
  }
] 

const data3 = [
  {
    icon: ClaIcon,
    title: "Knowledge Development Partner",
    to: "https://www.claonline.in/",
  }
]


const data4 = [
  {
    icon: Centre4MediationIcon,
    title: "",
    to: "http://bombaychamber.com/admin/uploaded/Download/Presentation%20on%20Centre%20for%20Mediation%20and%20Conciliation%20-%20CMC.pdf",
  }
]

const data5 = [
  {
    icon: VidhyaIcon,
    title: "Knowledge Sharing Partner",
    to: "https://www.vidhionline.com/",
  }
]

const data6 = [
  {
    icon: IlaIcon,
    title: "Ecosystem Partner",
    to: "https://www.indialawyers.org/",
  },
  {
    icon: LegoDeskIcon,
    title: "",
    to: "https://legodesk.com/",
  },
  {
    icon: IResolveIcon,
    title: "",
    to: "https://www.iresolve.co.tz/",
  }

]

export default function quickpartners() {
  return (
    <div id="partners" className="partners">
      <div
        className="partners__content "
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="700"
      >
        <h2
          className="main-heading text-blue main-heading--capital"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="700"
        >
          Our partners
        </h2>
      </div>

      <div
        className="partners__content "
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="700"
      >
        
        <h5>Thought Leadership Partner</h5>        
        <div className="partners__containerbox">
          
          {data1.map(singleEntry => {
            return (
              
              <div className="partners__icon-containerbox-page" key={singleEntry.title}>
                 
                 <Link to={singleEntry.to} target="_blank">
                  <img src={singleEntry.icon} alt={singleEntry.title} />
                </Link>
                
                <div className="buffer"></div>
              </div>
              
            )
          })}
        </div>
        <div className="partners__containerbox">
          
          {data2.map(singleEntry => {
            return (
              
              <div className="partners__icon-containerbox-page" key={singleEntry.title}>
                 
                 <Link to={singleEntry.to} target="_blank">
                  <img src={singleEntry.icon} alt={singleEntry.title} />
                </Link>
                
                <div className="buffer"></div>
              </div>
              
            )
          })}
        </div>
        <div className="partners__container">
        </div>
        
      </div>

      <div
        className="partners__content "
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="700"
      >
        
        <h5>Knowledge Development Partner</h5>        
        <div className="partners__containerbox">
          
          {data3.map(singleEntry => {
            return (
              
              <div className="partners__icon-containerbox-page" key={singleEntry.title}>
                 
                 <Link to={singleEntry.to} target="_blank">
                  <img src={singleEntry.icon} alt={singleEntry.title} />
                </Link>
                
                <div className="buffer"></div>
              </div>
              
            )
          })}
        </div>
        <div className="partners__containerbox">
          
          {data4.map(singleEntry => {
            return (
              
              <div className="partners__icon-containerbox-page" key={singleEntry.title}>
                 
                 <Link to={singleEntry.to} target="_blank">
                  <img src={singleEntry.icon} alt={singleEntry.title} />
                </Link>
                
                <div className="buffer"></div>
              </div>
              
            )
          })}
        </div>
        <div className="partners__container">
        </div>
        
      </div>

      <div
        className="partners__content "
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="700"
      >
        
        <h5>Knowledge Sharing Partner</h5>        
        <div className="partners__container">
          
          {data5.map(singleEntry => {
            return (
              
              <div className="partners__icon-container-page" key={singleEntry.title}>
                 
                 <Link to={singleEntry.to} target="_blank">
                  <img src={singleEntry.icon} alt={singleEntry.title} />
                </Link>
                
                <div className="buffer"></div>
              </div>
              
            )
          })}
        </div>
      </div>
      <div
        className="partners__content "
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="700"
      >
        
        <h5>Ecosystem Partner</h5>        
        <div className="partners__container">
          
          {data6.map(singleEntry => {
            return (
              
              <div className="partners__icon-container-page" key={singleEntry.title}>
                 
                 <Link to={singleEntry.to} target="_blank">
                  <img src={singleEntry.icon} alt={singleEntry.title} />
                </Link>
                
                <div className="buffer"></div>
              </div>
              
            )
          })}
        </div>
      </div>
    </div>
  )
}
